@mixin actions-hover() {

    &:hover &-actions {
        display: block;
        @include animation(fade-in .5s ease-in-out);
    }    

}

@mixin gradient (
    $bgColor,       // background-color
    $angle,         // gradient angle
    $gradientColor, // gradient color
    $opacityStart,  // opacity at start > 1 - 0
    $opacityEnd     // opacity at end > 1 - 0
) {
    background-color: $bgColor;
    background-image: linear-gradient(($angle * 1deg), rgba($gradientColor, $opacityStart), rgba($gradientColor, $opacityEnd));
    background-size: 100% 100%;
    background-position: 0 0;
    background-repeat: no-repeat;
}